import * as React from 'react';
import Layout from '../layout';
import { Message } from './styled';

const ErrorPage:React.FunctionComponent = () => {
    return <Layout>
      <Message>Contenu de la page</Message>
    </Layout>
}

export default ErrorPage;