import * as React from 'react';
import { HeaderWrapper, Logo, Catchline } from './styled';
import logo from './images/logo.png';
import {Helmet} from "react-helmet";
import {FormattedMessage} from 'react-intl';

const Header:React.FunctionComponent = () => {
    return <HeaderWrapper>
        <Logo src={logo} />
        <Catchline><FormattedMessage id="global_txt_title" defaultMessage="Catchline (default)"/></Catchline>
    </HeaderWrapper>;
}

export default Header;