import * as React from 'react';
import Layout from '../layout';
import { ShowContent, ShowMessage, ShareList, ShareListItem, ShareTitle, ShowWrapper, ShowTitle, Video, Sep } from './styled';
import { colors } from '../../variables';
import { Button } from '../../widgets/globaleStyledWidget';
import icoFacebook from './images/ico-facebook.svg';
import icoInsta from './images/ico-instagram.svg';
import icoTwitter from './images/ico-twitter.svg';
import livstickApp from '../../models/livstick';

//import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import { match, useHistory } from "react-router-dom";


import {FormattedMessage} from 'react-intl';
import manifest from '../../manifest';

const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};

interface DetailParams {
  id: string;
}

interface DetailsProps {
  match?: match<DetailParams>;
}

const PreviewPage:React.FunctionComponent<DetailsProps> = ({match}) => {

  const history = useHistory();

  const videoEl = React.useRef(null);

  const [message, setMessage] = React.useState<string>();

  const start = async () => {
    if (match.params.id) livstickApp.code = match.params.id;
    if (livstickApp.code == null) return history.push('/landing');

    if (match.path.startsWith('/answer')) livstickApp.answer = true;

    console.log(match);

    if (match.path == '/preview') return;

    const _res = match.path.startsWith('/preview') ? await livstickApp.pullNoLog() : await livstickApp.pull();
    if (_res.media_url == null || _res.media_url == "") history.push('/record');

    if (_res.text != undefined && _res.text != "undefined") setMessage(_res.text);
    
    console.log('ref', videoEl)
    var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION;
      videojs.log(version_info);
      _player.src({ src: _res.media_url });
      _player.load();
      _player.play();
    });

  }

  React.useEffect(() => {
    // start();
  }, [])


    return <Layout>
      <ShowWrapper>
        <ShowContent>
          <ShowTitle><FormattedMessage id="show_txt_title"/></ShowTitle>
          <Video>
            <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
          </Video>
          <ShowMessage dangerouslySetInnerHTML={{ __html: message}}></ShowMessage>
          {!livstickApp.answer && <Button color={colors.main} hover="main" onClick={() => {
            livstickApp.answer = true;
            history.push('/record')
          }}><FormattedMessage id="show_txt_answer"/></Button>}
          {/*
          <Sep/>
          <ShareTitle><FormattedMessage id="show_txt_share" defaultMessage="Partager mon message :"/> :</ShareTitle>
          <ShareList>
            <ShareListItem color={colors.facebook}><a><img src={icoFacebook} /> Facebook</a></ShareListItem>
            <ShareListItem color={colors.twitter}><a><img src={icoTwitter} />Twitter</a></ShareListItem>
            <ShareListItem color={colors.instagram}><a><img src={icoInsta} />Instagram</a></ShareListItem>
          </ShareList>
          */}
        </ShowContent>
      </ShowWrapper>
    </Layout>
}

export default PreviewPage;