import * as React from "react";
import {render} from "react-dom";

import App from "./App";
import {IntlProvider} from 'react-intl';

import {translations, translationsEn} from './lang/fixture';

import './global.scss'

const rootEl = document.getElementById("root");

const language = navigator.language.split(/[-_]/)[0];

console.log('language', language);

render(

        <App/>
    
    ,
    rootEl,
);
