import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import backgroundMobile from './images/background_mobile.jpg';
import backgroundDesktop from './images/background_desktop.jpg';

export const MainWrapper = styled.div`
  background-color: #F0F4F5;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;


const _PictureBackground = styled.div<RouteComponentProps>`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-size: cover;
    background-position: top center;
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    display: block;
    @media (max-width: 991px){
      background-position: top center;
      background-image: url(${backgroundMobile});
      display: block;
    }
    
`;

export const PictureBackground = withRouter(_PictureBackground);