import * as React from 'react';
import { Wrapper } from './styled';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent = () => {
    return <Wrapper>
        
        
    </Wrapper>;
}

export default Wrapper;